.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  background-color: var(--white);
  box-shadow: none;
  padding: 20px;
  max-height: 90%;
  overflow-y: scroll;
  position: relative;
  outline: none;
  border-radius: 8px;
}

.xIconContainer {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.topActionContainer {
  display: flex;
  justify-content: space-between;
}

.topActionContainer {
  color: var(--navy);
  text-align: right;
  font-family: 'OpenSans-Bold';
}

.xIconContainer:hover,
.xIconContainer:active {
  color: var(--grey);
  opacity: 0.6;
}

.bottomSheet {
  max-width: 500px;
  width: 100%;
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .bottomSheet {
    position: absolute;
    bottom: 0;
    border-radius: 5px 5px 0 0;
  }
}
