.imgModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  max-width: 1064px;
  animation: modal 0.5s;
  z-index: 3;
}

.modalXContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.postImageContainer {
  width: 100%;
  height: 80%;
}

.postImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.likeContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

.likeIcon {
  margin-right: 5px;
}

.likeIcon:hover {
  opacity: 0.6;
}

.downloadContainer {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 20px;
  left: 70px;
}

.downloadIcon {
  margin-right: 5px;
}

.downloadIcon:hover {
  cursor: pointer;
  opacity: 0.6;
}

.disableHover:hover {
  pointer-events: none;
  opacity: 1;
}

.prevButton, .nextButton {
  position: absolute;
  top: 50%; 
  background-color: white; 
  color:  var(--magenta);
  border-radius: 50%;
  width: 25px; 
  height: 25px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  border: none; 
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.prevButton {
  left: 10px; 
}

.nextButton {
  right: 10px; 
}

.carouselContainer {
  position: relative;
}

.carouselIndicators {
  position: absolute;
  bottom: 10px; 
  left: 50%;
  text-align: center;
  padding: 10px;
  z-index: 10; 
  transform: translateX(-50%);
}

.indicator {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: grey;
  margin: 0 2px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.indicator.active {
  width: 10px;
  border-radius: 10px;
  background-color: var(--magenta);
}
