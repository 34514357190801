.container {
    padding: 6px 0 16px;
    background-color: var(--white);
}

.sectionTitle {
    padding: 10px 40px 0;
    display: block;
}

.messageTitle {
    padding: 0 10px;
    justify-content: space-between;
    display: flex;
}

.result {
    border-bottom: 1px solid var(--grey20);
    padding: 10px 30px;
}

.result > div {
    margin: 0;
}

.seeAll {
    display: block;
    text-align: center;
    margin: 10px;
}

.seeAll:hover {
    cursor: pointer;
}

.searchedChatContainer {
    padding: 10px 40px;
    border-bottom: 1px solid var(--grey20);
    cursor: pointer;
}

.nameAndTime {
    display: flex;
    margin-bottom: 4px;
}

.searchedChatNameContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.searchedChatNameContainer p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchedChatTime {
    display: flex;
    flex: .4;
    justify-content: flex-end;
}

.noResults {
    text-align: center;
}
