.container {
  background-color: white;
}

.searchBar {
    border: '1px solid var(--grey20)'
}

.createHeaderButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.chatCreationContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    min-height: 40px;
    gap: 10px;
    padding: 0 10px;
  }
  
  .userCount {
    white-space: nowrap;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    align-self: center;
    padding-left: 10px;
  }
  
  .userListContainer {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px;
    padding: 5px 10px;
    flex-grow: 1;
    align-items: center;
    min-height: 35px;
  }
  
  .userPill {
    display: flex;
    align-items: center;
    height: 28px;
    padding: 5px 15px;
    border-radius: 20px;
    background: #041E42;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    flex-shrink: 0;
  }

  .disabledUserPill {
    background-color: white;
    color: var(--grey);
    border: 1px solid var(--grey20);
  }
  
  .removeUserButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .addButton {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: transparent;
    cursor: pointer;
  }
.resultWrapper {
  overflow-y: auto;
}
