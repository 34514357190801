.container {
    padding: 0 10px;
    position: relative;
}

.imgAndTextContainer p {
    font-size: 12px;
    max-height: 62px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-clamp: 3;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.imgWrapper {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.imgWrapper img {
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.imgAndTextContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.replyTextContainer {
    padding: 4px;
    margin: 0;
    border: 1px solid var(--grey40);
    border-radius: 8px;
}

.vertical {
    position: absolute;
    width: 2px;
    background-color: var(--grey20);
    left: 29px;
    top: 34px;
}
  
.leftUp {
    position: absolute;
    width: 70px;
    border-left: 2px solid var(--grey20);
    border-top: 2px solid var(--grey20);
    border-top-left-radius: 16px;
}
  
.downRight {
    position: absolute;
    width: 70px;
    border-left: 2px solid var(--grey20);
    border-bottom: 2px solid var(--grey20);
    border-bottom-left-radius: 16px;
}
