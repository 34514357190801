.followButton {
  flex: 1;
  display: flex;
  background-color: var(--grey5);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.smallFollowButton {
  flex: 1;
  display: flex;
  background-color: var(--grey5);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 180px;
}

.followIconContainer {
  display: flex;
  align-items: center;
  gap: 8px ;
}

.followButton:active,
.followButton:hover {
cursor: pointer;
opacity: 0.6;
}