@keyframes highlight-animation {
    0% {
        background-color: var(--grey5);
    }
    60% {
        background-color: var(--grey5);
    }
    100% {
        background-color: inherit;
    }
}

.seeked {
    animation: highlight-animation 3s linear 1;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100dvh;
}
.messagesContainer {
    max-height: 100dvh;
    flex: 1;
    overflow-y: auto;
    overflow-x: visible;
    scrollbar-width: thin;
    scrollbar-gutter: stable;
}
.dateHeader {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--grey);
}
.inactiveChat {
    text-align: center;
    padding: 16px;
    border-top: 1px solid var(--grey20);
}
  
/*
TODO: Anywhere we use vh as a unit, we want to change to dvh. 
This resolves the overflow issue on mobile web browsers, and 
MO will improve the experience, but this will require QA to 
test every screen on web. This was a recent addition(2021/2022).
See https://www.w3.org/TR/css-values-4/#viewport-variants
*/
