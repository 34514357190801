/* Navigation Button */

.button {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-radius: 30px;
  color: var(--grey80);
}

.active {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-radius: 30px;
  background-color: var(--white);
  color: var(--magenta);
}

.button p,
.active p {
  margin-left: 10px;
}

.icon {
  align-self: center;
  height: 24px;
  width: 24px;
}

.iconWithNotificationContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.notificationDot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  position: absolute;
  left: 13px;
  top: 20px;
  background-color: var(--magenta);
}

.messagesDot {
  top: 15px
}

/* Responsive */
@media only screen and (max-width: 768px) {
  .button {
    width: 100%;
    height: unset;
    padding: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .active {
    width: 100%;
    height: unset;
    padding: 0;
    border-radius: 0;
    background-color: unset;
    color: var(--magenta);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .button p,
  .active p {
    display: inline-block;
    margin-left: 0;
  }
  .icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin-bottom: 10px;
  }
  .notificationDot {
    top: 0px;
  }
  .messagesDot {
    top: -2px
  }
}
