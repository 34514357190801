.contentContainer {
    padding-right: 40px;
    padding-left: 40px;
    background-color: white;
}

.groupTextContainer {
    margin-top: 20px;
    text-align: center;
}

.groupNameText {
    color: var(--magenta);
    font-size: 20px;
    font-family: 'OpenSans';
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    margin-bottom: 10px;
}

.changeGroupNameText {
    color: var(--magenta);
    font-size: 16px;
    font-family: 'OpenSans';
    font-weight: 600;
    word-wrap: break-word;
}

.changeGroupNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    width: 100%;
    height: 100%; 
}

.buttonContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.iconButtonContent {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: flex-start;
}

.buttonText {
    color: var(--magenta);
    font-size: 12px;
    font-family: 'OpenSans';
    font-weight: 700;
    word-wrap: break-word;
}

.chatMemberLabel {
    color: var(--grey);
    font-size: 12px;
    font-family: 'OpenSans';
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
    margin-bottom: 5px;
}
