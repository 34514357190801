.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background-color: var(--white);
}

.messageContainer {
    display: flex;
    border: 1px solid var(--grey40);
    border-radius: 18px;
    padding: 10px;
    margin: 10px;
    flex: 1;
}

.textInput {
    border: 0;
    width: 98%;
    height: 30px;
    resize: none;
    overflow-y: auto;
    max-height: 100px;
}

.modalXContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--magenta);
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    top: -8px;
    right: -8px;
}

.textInputWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.messagePhotosContainers {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

.imgContainer {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 570px;
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    padding: 8px 0;
    gap: 16px;
}

.photoWrapper {
    height: 100%;
    position: relative;
}

.textInput:focus-visible {
    outline: 0;
}

.postIconContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.postIconBackground {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--navy);
}

.disabledPostIconBackground {
    background-color: var(--white);
    border: 1px solid var(--grey40);
}

.textLimitContainer {
    position: absolute;
    bottom: -5px;
    background: var(--grey40);
    border-radius: 3px;
    color: var(--grey);
    padding: 2px;
}

.attachPhotoButton {
    position: relative;
    height: 26px;
    width: 26px;
}

.hideDefaultUpload {
    display: none;
}

.sitePreviewContainer {
    padding: 10px 10px 0;
}

.editReplyContainer {
    width: 100%;
    padding: 8px 10px;
    background: white;
    border-top: 1px var(--grey20) solid;
    border-bottom: 1px var(--grey20) solid;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: inline-flex;
}

.editReplyContainer h4 {
    text-transform: none;
}

.editReplyTextContainer {
    display: flex;
    align-items: center;
}

.editAndReplyText {
    color: var(--grey);
    font-size: 12px;
    font-weight: 700;
    word-wrap: break-word;
    margin-right: 5px;
}

.cancelEditReplyButtonContainer {
    background-color: var(--grey);
    border-radius: 20px;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.cancelEditReplyButtonContainer:hover {
    cursor: pointer;
}
