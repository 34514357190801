/* Message Option Styling */
.messageOptionContainer {
    position: absolute;
    display: none;
    flex-shrink: 1;
    gap: 8px;
    cursor: pointer;
    z-index: 2;
}

.messageOptionContainerLeft {
    transform: translateX(100%);
    right: -8px;
}

.messageOptionContainerRight {
   transform: translateX(-100%);
   flex-direction: row-reverse;
   left: -8px;
}

.messageOptionIconContainer {
    width: 24px;
    height: 24px;
}

.retryIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Reactions Styling */
.reactionOptions {
    display: none;
    position: relative;
    width: fit-content;
    height: fit-content;
    gap: 4px;
    padding: 6px;
    border-radius: 22px;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--white);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.reactionIcon:hover > .reactionOptions {
    display: flex;
}

.reactionItem {
    font-size: 24px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.reactionItem:hover {
    background-color: var(--grey8);
}

.moreEmojiIcon {
    transform: rotate(90deg);
}

.replyIcon {
    transform: scaleX(-100%); /* flips the icon 180 deg horizontally */
}

.optionIcon:hover > .additionalOptions {
    display: block;
}

.additionalOptions {
    display: none;
    position: relative;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--white);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

/* Additional Options Styling (Edit, Delete, Report, Block) */
.additionalOptionItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 10px;
    gap: 5px;
    flex: 1;
}

.additionalOptionItem:hover {
    background-color: var(--grey8);
}

.additionalOptionItem:first-of-type {
    padding: 10px 10px 6px;
}

.additionalOptionItem:last-of-type {
    padding: 6px 10px 10px;
}

/* Tooltip Triangle */
.reactionOptions::after,
.additionalOptions::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--white) transparent;
}
