.modalContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.groupNameInput {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    border: none;
    font-family: 'OpenSans';
    color: var(--magenta);
    border: none;
    border-radius: 5px;
}
