.headerContainer {
  width: 100%;
  background: var(--magenta);
  display: flex;
  padding: 20px 40px;
  min-height: 88px;
}

.noBottomPadding {
  padding-bottom: 0px;
}

.lessHeightContainer {
  padding: 10px 40px;
  min-height: 60px;
}

.headerTextContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-right: 40px;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.noRightTextPadding {
  padding-right: 0px;
}

.leftAlignText {
  align-items: flex-start;
  padding-left: 20px;
}

.truncatedTitle {
  display: inline-block;
  overflow: hidden;
  line-clamp: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 30px;
}


/* Responsive */
@media only screen and (max-width: 768px) {
  .headerContainer {
    padding: 20px;
  }

  .noBottomPadding {
    padding-bottom: 0px;
  }

  .lessHeightContainer {
    padding: 10px 20px;
    min-height: 60px;
  }

  .headerTextContainer {
    padding-right: 20px;
  }
}
