
.parentContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
}

.messageContainer {
    max-width: 66%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: pre-wrap;
}

.pendingContainer {
    background-color: var(--navy);
    animation: fade-animation 4s linear infinite;
}

@keyframes fade-animation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
    100% {
        opacity: 0.5;
      }
  }

.failedSendContainer {
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--magenta);
}

.failedSendText {
    color: var(--magenta);
}

.sentContainer {
    background-color: var(--navy);
}

.sentText {
    color: var(--white);
}

.receivedContainer {
    background-color: var(--grey8);
}

.receivedText {
    color: var(--grey);
}

.textContainer {
    border-radius: 8px;
    padding: 10px;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: anywhere;
    flex-shrink: 1;
}

.nameAndTimeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
}

.timestamp {
    font-size: 12px;
    color: var(--grey40);
}

.textAndOptionsContainer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
}

.parentContainer:hover .messageOptionContainer {
    display: flex;
}

.messageProfileImage {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
    margin: 24px 10px 0 0;
    align-self: flex-start;
}
.reactionsContainer {
    flex-direction: row;
    margin-top: 4px;
    display: flex;
    gap: 4px;
}

.reaction {
    flex-direction: row;
    padding: 4px;
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.hoverEffects:hover {
    cursor: pointer;
}

/* Mobile Responsive */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .messageContainer {
        max-width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .messageContainer {
        max-width: 62%;
    }
}

@media only screen and (max-width: 480px) {
    .messageContainer {
        max-width: 50%;
    }
}
