.container {
    border-color: var(--grey8);
    position: relative;
    padding-left: 363px;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
}

.searchBarWrapper {
    padding: 10px 40px 0;
}

.noChatsContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.noChatsPostIconContainer {
    display: flex;
    height: 80px;
    width: 80px;
    background-color: var(--magenta);
    border-radius: 40px;
    margin-bottom: 11px;
    justify-content: center;
    align-items: center;
}

.noChatsPostIconContainer:hover,
.chatPostIconContainer:hover
 {
    cursor: pointer;
}

.chatPostIconContainer {
    width: 45px;
    height: 45px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hasChatsContainer {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chatsContainer {
    flex: 1;
    background-color: var(--white);
}

.editHeaderButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

@media only screen and (max-width: 768px) {
    .container {
      padding-left: 0;
      padding-bottom: 86px;
    }
}
