.container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    max-height: 94px;
    border-radius: 8px;
    box-shadow: none !important;
    cursor: pointer;
}

.imageContainer {
    width: 94px !important;
    height: 94px;
    object-fit: cover;
    aspect-ratio: 1/1; /* added for redundancy */
}

.textContainer {
    padding: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    justify-content: center;
}

.textContent {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 22px;

}

.url {
    font-size: 12px;
}

.bold {
    font-family: 'OpenSans-Bold';
    font-weight: 700;
}

.fallbackIconWrapper {
    height: 94px;
    width: 94px;
    aspect-ratio: 1/1; /* added for redundancy since width wasn't being set */
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--grey20);
}

.fallbackIcon {
    height: 70%;
    width: 70%;
}
