.carouselContainer {
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .imagesContainer {
    display: flex;
    align-items: center; 
    justify-content: center;
    transition: transform 0.5s ease;
  }
  
  .carouselImage {
    transition: opacity 0.5s ease;
    margin: 0 10px;
    height: 100%;
    width: auto;
  }
  
  .adjacent {
    width: 0;
    opacity: 0.5;
  }
  
  .focused img {
    opacity: 1;
  }

  .adjacent:first-of-type img {
    transform: translateX(calc(-100% - 20px));
}
  
  .carouselIndicators {
    text-align: center;
    padding: 10px;
  }
  
  .indicator {
    display: inline-block;
    width: 5px; 
    height: 5px;
    border-radius: 50%;
    background-color: grey;
    margin: 0 2px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .indicator.active {
    width: 10px;
    border-radius: 10px;
    background-color: var(--magenta);
  }
  
  .prevButton, .nextButton {
    transform: translateY(-50%);
    position: absolute;
    top: 50%; 
    background-color: white; 
    color:  var(--magenta);
    border-radius: 50%;
    width: 25px; 
    height: 25px; 
    display: flex;
    align-items: center; 
    justify-content: center;
    border: none; 
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .prevButton {
    left: 10px; 
  }
  
  .nextButton {
    right: 10px; 
  }