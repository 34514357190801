.messageButton {
    flex: 1;
    display: flex;
    background-color: var(--grey5);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.messageButton:active,
.messageButton:hover {
  cursor: pointer;
  opacity: 0.6;
}