.container {
    border-bottom: 1px solid var(--grey8);
    display: flex;
    padding: 16px 40px;
    overflow: hidden;
}

.container:hover {
    cursor: pointer;
}

.nameAndMessageContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    justify-content: center;
}

.overflowEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.unreadAndTimeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 80px;
    position: relative;
}

.timeStamp {
    font-family: 'OpenSans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.checkBoxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.checkBox {
    height: 18px !important;
    width: 18px !important;
    opacity: 1 !important;
    border-color: var(--grey40);
    accent-color: var(--magenta);
}
