.reportModalContent {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.reportModalText {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.reportTextArea {
    width: 100%;
    height: 80px;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    font-family: 'OpenSans';
    text-align: center;
    font-size: 12px;
    resize: none;
}

.leftAlignedButtonText {
    text-align: left;
    width: 100%;
    padding-left: 10px;
}

