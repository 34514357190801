.imgContainer {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    width: fit-content;
}

.images {
    border-radius: 8px;
    border: 2px solid var(--white);
    height: auto;
    object-fit: cover;
}
